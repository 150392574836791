.add-edit-playlist{
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-column-gap: 1rem;
    margin: 1rem;
}
.add-edit-buttons{
    grid-row: 1;
}

.add-edit-buttons .MuiChip-colorPrimary:hover {
    background-color: rgb(0, 107, 155);
}

.add-edit-buttons .MuiChip-colorPrimary.active {
    background-color: rgb(0, 107, 155);
}

.add-edit-content{
    grid-row: 3;
    grid-column: 1;
    justify-items: end;
    margin-bottom: 2rem;
}

.add-edit-content-details{
    grid-row: 2;
    grid-column: 1;
    justify-items: start;
    margin-bottom: 1rem;
}

.add-edit-content-buttons{
    grid-row: 4;
    grid-column: 1;


}

.add-edit-selected-filename {
    margin-bottom: 0;
}