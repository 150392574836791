.playlist{
    display: grid;
    grid-template-columns: 1fr 375px;
    background: #eee;
    max-height: 532px;
}

.playlist-inner-wrapper {
    max-height: 532px;
    overflow-y: scroll;
}

.playlist-item-metadata {
    padding: 0.5rem 0 0.5rem 0.5rem;
    min-height: 72px;
    display: grid;
    grid-template-columns: 1fr 225px;
}

.playlist-actions {
    display: flex;
    justify-content: flex-end;
}

.playlist-item{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto;
    padding: 0.5rem;
    margin: 0.25rem;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: 50px;
}

.editable.playlist-item{
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.playlist-item-details{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 170px auto;
}

.editable .playlist-item-details{
    grid-auto-columns: 15px 170px auto;
}

.playlist-item-drag-indicator {
    margin: auto;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    opacity: 0.65;
}

.playlist-item-drag-indicator:hover {
    opacity: 1;
}

 /* (Optional) Apply a "closed-hand" cursor during drag operation. */
 .playlist-item-drag-indicator:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;    
}

.playlist-item-edit{
    align-self: start;
}

.playlist-item-progress{
    grid-column: 2;
    grid-row: 1;
    max-height: 100%;
    max-width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
}
.playlist-item-thumbnail-outer{
    width: 170px;
    text-align: center;
}

.playlist-item-thumbnail{ 
    aspect-ratio: auto 16 / 9;
    line-height: 0;
    color: #BFBFBF;
}
.playlist-item-thumbnail-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.playlist-item-thumbnail-icon{
    width: 100px !important;
    height: auto !important;
}

.playlist-item-text {
    margin-left: 0.5rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.playlist-item-title{
    font-weight: bold;
    font-size: 14px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    height: 1rem;
    display: flex;
    -webkit-box-orient: vertical;
}
.playlist-item-description{
    margin-top: .5rem;
    display: block;
    line-height: 1.25;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    height: 2rem;
    -webkit-box-orient: vertical;
}
.playlist-item-updated-date{
    display: block;
    font-weight: 300;
    font-size: 0.675rem;
    letter-spacing: -0.25px;
}

.player-inner {
    aspect-ratio: auto 16 / 9;
    line-height: 0;
}

.video-playlist-item,
.pdf-playlist-item,
.iframe-playlist-item,
.empty-playlist-item{
    width: 100%;
    height: 100%;
}

.empty-playlist-item{
    background: #ccc;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}