.thumbnail-selection {
    margin: 2rem 0 1rem;
    color: rgba(0, 0, 0, 0.54);
}

.thumbnail-selection-image {
    object-fit: cover;
}

.thumbnail-selection-instructions {
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 0.25rem;
}